<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row class="pa-0 ma-0" align="center" justify="center" v-if="loading">
      <v-progress-circular indeterminate color="primary" />
    </v-row>
    <v-row justify="stretch" align="center" :no-gutters="noGutters" class="pa-0 ma-0" v-else>
      <v-col cols="6" xs="6" sm="6" md="4" lg="3" xl="2" v-for="(photo, n) in photos" :key="n">
        <v-card dark :href="photo.url">
          <v-img contain aspect-ratio="1" :src="photo.thumbnailUrl">
            <template v-slot:placeholder>
              <v-container fluid class="fill-height pa-0 ma-0">
                <v-row class="pa-0 ma-0 fill-height" justify="center" align="center">
                  <v-progress-circular indeterminate>
                  </v-progress-circular>
                </v-row>
              </v-container>
            </template>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from '@/plugins/firebase'

// const FUNCTION_NAME = 'getPhotosWithPrefix'
// const BUCKET_ADDRESS = 'https://storage.googleapis.com/moonhope-174701.appspot.com'
const STORAGE = firebase.storage().ref()

export default {
  name: 'GCSPhotoGallery',

  props: {
    // Use this prop to set the GCS prefix from which files would be taken.
    prefix: {
      type: String,
      required: true
    },

    // Limit maximum items
    'max-results': Number,
    'page-token': Number,

    'no-gutters': Boolean
  },

  data () {
    return ({
      loading: false,
      photos: []
    })
  },

  methods: {
    getPhotoRefs () {
      // return firebase.functions().httpsCallable(FUNCTION_NAME)(this.prefix)
      return new Promise((resolve, reject) => {
        // var resolution = new Array()
        var listOptions = {}

        if (this.maxResults) { listOptions.maxResults = this.maxResults }
        if (this.pageToken) { listOptions.pageToken = this.pageToken }

        STORAGE.child(this.prefix).list(listOptions)
          .then(refs => {
            // refs.items.forEach(item => {
            //   item.getDownloadURL().then(url => { resolution.push(url) })
            // })
            var thumbnailPairings = refs.items.map(ref => ({
              // if (ref.fullPath.search(/thumbnails\//) === -1) {
              //   thumbnailPairings.push({
              //     photoRef: ref,
              //     thumbnailRef: ref.child(`thumbnails/${ref.name}`)
              //   })
              // }
              photoRef: ref,
              thumbnailRef: STORAGE.child(`${this.prefix}/thumbnails/${ref.name}`)
            }))

            // console.log(thumbnailPairings)

            resolve(thumbnailPairings)
          })
          .catch(e => {
            reject(e)
          })
      })
    },

    async getURLs (a) {
      return ({
        url: await a.photoRef.getDownloadURL(),
        thumbnailUrl: await a.thumbnailRef.getDownloadURL() || await a.photoRef.getDownloadURL()
      })
    },

    async getPhotos () {
      var photoRefs = await this.getPhotoRefs()
      var urls = await Promise.allSettled(photoRefs.map(ref => this.getURLs(ref)))
      return urls
    }
  },

  async mounted () {
    this.$data.loading = true

    try {
      this.$data.photos = await this.getPhotos()
    } catch (e) {
      console.error('[gcs-photo-gallery] Failed to populate photos', e)
    }

    this.$data.loading = false
  }
}
</script>
