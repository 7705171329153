<template>
  <v-container class="fill-height">
    <v-row><h2 class="text-h3" :class="isOnPC ? '' : 'mx-1'">Gallery</h2></v-row>
    <v-row v-if="loading" justify="center" align="stretch">
      <v-progress-circular color="primary" indeterminate size="48" />
    </v-row>
    <v-row v-else>
      <gallery-fragment
        :class="isOnPC ? 'my-6' : 'my-1'"
        v-for="subGallery in subGalleries"
        :gcs-prefix="subGallery.path"
        :title="subGallery.name"
        :key="subGallery.path"
      />
    </v-row>
  </v-container>
</template>

<script>
import GalleryFragment from '@/components/GalleryPage/GalleryFragment'
import firebase from '@/plugins/firebase'

const STORAGE = firebase.storage().ref()

export default {
  name: 'GalleryView',

  components: {
    'gallery-fragment': GalleryFragment
  },

  data () {
    return ({
      loading: true,
      subGalleries: []
    })
  },

  computed: {
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },

  methods: {
    async getSubGalleries () {
      return new Promise((resolve, reject) => {
        STORAGE.child('gallery').listAll()
          .then(results => {
            var resolution = results.prefixes.map(prefix => ({
              name: prefix.name,
              path: prefix.fullPath
            }))
            resolve(resolution)
          })
          .catch(e => { reject(e) })
      })
    }
  },

  async mounted () {
    this.$data.loading = true

    this.$data.subGalleries = await this.getSubGalleries()

    this.$data.loading = false
  }
}
</script>
