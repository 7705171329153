<template>
  <v-container fluid class="pa-0 pa-0">
    <h3 class="text-h5" :class="isOnPC ? '' : 'mx-2'" v-if="title">{{ title }}</h3>

    <gcs-photo-gallery :max-results="maxResults" :prefix="gcsPrefix" />
  </v-container>
</template>

<script>
import GcsPhotoGallery from '@/components/gcsPhotoGallery'

export default {
  name: 'GalleryFragment',

  components: {
    'gcs-photo-gallery': GcsPhotoGallery
  },

  props: {
    'gcs-prefix': {
      type: String,
      required: true
    },
    title: String,
    'max-results': Number
  },

  computed: {
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  }
}
</script>
